.App {
    display: flex;
    justify-content: center;
}

body {
    background-color: #CDACA1;
    margin-top: 100px;
    margin-bottom: 140px;
}

h1, h2, h3, h4, h5 {
    text-align: center;
    margin-left: 75px;
    margin-right: 75px;
}

body, button, textarea {
    font-family: "Lucida Sans Unicode", "Lucida Grande", sans-serif;
}

.footer, nav {
    background-color: #CDD6DD;
    position: fixed;
    left: 0;
    right: 0;
    padding-left: 10px;
    padding-right: 10px;
    align-items: center;
    display: flex;
    justify-content: space-around;
    box-shadow: 0 0 25px 0 gray;
}

.footer {
    bottom: 0;
    text-align: center;
}

nav {
    top: 0;
}

nav ul {
    padding: 0;
}

nav * {
    display: inline;
}

nav li {
    margin-left: 4vw;
    margin-right: 4vw;
    padding: 0;
}

nav a {
    color: black;
    text-decoration: none;
    font-weight: bold;
    border-radius: 25px;
}

nav a:hover {
    background-color: #CDCACC;
}

textarea {
    margin: 10px 30px;
    padding: 5px;
    border-radius: 10px;
    font-size: 10pt;
    resize: none;
    outline: none;
    border: 1px solid gray;
}

.browse, .search, .excerpt, .loadScreen {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.aboutParagraph {
    margin-left: 50px;
    margin-right: 50px;
    width: 1000px;
}

.buttonComponent {
    background-color: #CDD6DD;
    color: black;
    font-size: 18px;
    width: 200px;
    height: 40px;
    margin-top: 10px;
    margin-bottom: 10px;
    border: 1px solid black;
    border-radius: 25px;
    outline: none;
    cursor: pointer;
}

.buttonComponent:hover {
    background-color: #CCFBFE;
}

.buttonComponent:active:hover {
    background-color: #CDCACC;
    color: #666666;
    border: 1px solid #666666;
    cursor: default;
}
